import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import truncate from 'truncate-html'

import { getProductURL, getSlug } from '../utils/url'

const ProductSlider = ({ title, products }) => {
	return (
		<div className="tm-product-slider uk-margin">
			<h2 className="uk-heading-line">
				<span>{title}</span>
			</h2>
			<div
				className="uk-slider-container-offset"
				uk-slider="autoplay: true; sets: false"
			>
				<div
					className="uk-position-relative uk-visible-toggle uk-dark"
					tabIndex="-1"
				>
					<ul
						className="uk-slider-items uk-child-width-1-1@s uk-child-width-1-2@m uk-grid"
						uk-height-match=".uk-card-body"
					>
						{products.map((product) => (
							<li key={getSlug(product)}>
								<div className="uk-card uk-card-default uk-card-hover">
									<div className="uk-card-media-top" style={{ height: 256 }}>
										<Link to={getProductURL(getSlug(product))}>
											<GatsbyImage
												className="uk-display-block uk-margin-auto"
												image={getImage(product.image.asset)}
												alt={product.title}
												style={{ height: '100%' }}
												imgStyle={{ objectFit: 'contain' }}
											/>
										</Link>
									</div>
									<div className="uk-card-body">
										<div className="uk-article-meta">
											<span>REF: {product.sku || 'N/A'}</span>
										</div>
										<h4 className="uk-card-title uk-margin-small-top">
											<Link
												to={getProductURL(getSlug(product))}
												className="uk-link-heading"
											>
												{product.title}
											</Link>
										</h4>
										<div
											dangerouslySetInnerHTML={{
												__html: truncate(product.shortDescription, 256),
											}}
										></div>
									</div>
									<div className="uk-card-footer">
										<div className="uk-grid-small" data-uk-grid>
											<div className="uk-width-expand"></div>
											<div className="uk-width-auto">
												<Link
													to={getProductURL(getSlug(product))}
													className="uk-button uk-button-text"
												>
													Ver mais
												</Link>
											</div>
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>

					<a
						className="uk-position-center-left uk-position-small uk-hidden-hover"
						href="#"
						data-uk-slidenav-previous
						uk-slider-item="previous"
					></a>
					<a
						className="uk-position-center-right uk-position-small uk-hidden-hover"
						href="#"
						data-uk-slidenav-next
						uk-slider-item="next"
					></a>
				</div>

				{/* note: this element is required (bug) to create cards' bottom shadows, but
                  we don't want to show it because it's not necessary */}
				<ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin-small uk-invisible"></ul>
			</div>
		</div>
	)
}

ProductSlider.propTypes = {
	title: PropTypes.string.isRequired,
	products: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ProductSlider
