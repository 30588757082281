import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Slideshow = ({ slides }) => (
	<div
		className="uk-position-relative uk-visible-toggle uk-dark"
		tabIndex="-1"
		uk-slideshow="autoplay: true; ratio: 1440:900"
	>
		<ul className="uk-slideshow-items">
			{slides.map((slide, index) => (
				<li key={`slide-${index}`}>
					<GatsbyImage
						image={getImage(slide.image.asset)}
						alt={slide.alt || slide.title}
						data-uk-cover
						style={{ position: 'initial' }}
					/>
					{/* hack: linking the slide. two divs are necessaire to don't mess with touch move */}
					<Link to={slide.url}>
						<div className="uk-position-cover">
							<div className="uk-position-center"></div>
						</div>
					</Link>
				</li>
			))}
		</ul>

		<a
			className="uk-position-center-left uk-position-small uk-hidden-hover"
			href="#"
			data-uk-slidenav-previous
			uk-slideshow-item="previous"
		></a>
		<a
			className="uk-position-center-right uk-position-small uk-hidden-hover"
			href="#"
			data-uk-slidenav-next
			uk-slideshow-item="next"
		></a>

		<ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
	</div>
)

export default Slideshow
