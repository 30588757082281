import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Slideshow from '../components/slideshow'
import ProductSlider from '../components/product-slider'

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query IndexData {
			allProductFeatured: allSanityProduct(filter: { featured: { eq: true } }) {
				edges {
					node {
						title
						slug {
							current
						}
						sku
						shortDescription
						image {
							asset {
								gatsbyImageData(width: 256, layout: FIXED, placeholder: BLURRED)
							}
						}
					}
				}
			}
			allProductRecent: allSanityProduct(
				filter: {}
				sort: { fields: _createdAt, order: DESC }
				limit: 6
			) {
				edges {
					node {
						title
						slug {
							current
						}
						sku
						shortDescription
						image {
							asset {
								gatsbyImageData(width: 256, layout: FIXED, placeholder: BLURRED)
							}
						}
					}
				}
			}

			allSlide: sanityHomePage {
				homeSlides {
					url
					title
					image {
						asset {
							gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
						}
					}
				}
			}
		}
	`)
	return (
		<Layout>
			<SEO title="Início" />
			<Slideshow slides={data.allSlide.homeSlides} />
			<ProductSlider
				title="Destaque"
				products={data.allProductFeatured.edges.map((edge) => edge.node)}
			/>
			<ProductSlider
				title="Recentes"
				products={data.allProductRecent.edges.map((edge) => edge.node)}
			/>
		</Layout>
	)
}

export default IndexPage
